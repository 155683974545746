import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Mobile App <span class="h1-span">Development Company</span>`,
    text: "Creative Mobile App Development Services",
    content: "Our Mobile App Development Company excels in creating custom apps for Android and iOS. With a team of expert mobile app developers, we transform your ideas into innovative solutions.",
    list: [
      'Flexible Engagement Model',
      'Custom Mobile App Services',
      'Full Cycle Application Development'
    ]
  };
  const ourData = {
    title: "Achievements Of Our Mobile App Development Services",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Boost Growth With Mobile <span class='h1-span'>App Development Agency</span>",
    content: [
      {
        title: "7+ Years of Mobile App Development Expertise",
        text: "With over 7+ years of mobile app development, our developers excel in crafting cutting-edge mobile app solutions that seamlessly blend functionality and innovation to ensure unparalleled user experiences.",
      },
      {
        title: "Custom Mobile App Solution for 25+ Industries",
        text: "By understanding the unique challenges of diverse industries, we are able to architect and offer custom mobile app development services that seamlessly integrate with your business operations.",
      },
      {
        title: "50+ Mobile Apps Developed",
        text: "With over 50+ successfully launched mobile apps, our mobile app development services create user-friendly, feature-rich, and fully functional mobile app solutions.",
      },
      {
        title: "27% Increased User Adoption",
        text: "Our mobile app development company creates intuitive and value-driven experiences that has helped our clients achieve an average 27% increase in user adoption and retention.",
      },
    ],
  };
  const commonContent1 = {
    title: `Succeed With Our Mobile <span class="h1-span">App Development Company</span>`,
    content: [
      {
        title: "Experienced Developers",
        text: "Our team of experienced mobile application developers has the required knowledge and expertise and leverages the latest technologies and industry insights to create innovative mobile solutions for your business.",
      },
      {
        title: "Flexible Engagement Model",
        text: "Our custom mobile app development company offers a flexible engagement model tailored to your project requirements, ensuring seamless collaboration and timely delivery of customized solutions.",
      },
      {
        title: "Agile Methodology",
        text: "Our mobile app development agency uses agile methodology during the development process and prioritizes adaptability and continuous improvement, ensuring that your app meets the dynamic market needs.",
      },
      {
        title: "Strict Development Process",
        text: "We follow a strict mobile application development process and industry best practices to create robust, scalable, and user-friendly apps that meet the highest quality and performance standards.",
      },
      {
        title: "Rigorous Quality Check",
        text: "Our mobile application undergoes a rigorous quality check to ensure it meets our stringent criteria for functionality, security, and user experience, guaranteeing your users a polished and reliable product.",
      },
      {
        title: "Quick Turnaround Time",
        text: "With our agile approach to mobile application development, we prioritize efficiency and responsiveness, delivering your app within tight deadlines to help you capitalize on market opportunities and stay ahead of the competition.",
      },
    ],
  };
  const Questions = [
    "What mobile app development services do you offer?",
    "Why should I choose Octet as a mobile app development company?",
    "How much time do you take for mobile application development?",
    "How much does it cost to develop a mobile app with Octet?"
  ];
  const faqData = [
    {
        list: [
            `<span><h4 class="inline">iOS App Development:</h4></span> Our iOS app development services provide customized applications for Apple devices, ensuring seamless performance, superior user experience, and adherence to Apple’s strict guidelines.`,
            `<span><h4 class="inline">Android App Development:</h4></span> We specialize in creating robust, user-friendly Android apps that run smoothly across various devices and Android versions, maximizing your reach in the largest mobile market.`,
            `<span><h4 class="inline">Hybrid App Development:</h4></span> Our hybrid app development combines the best of both worlds, delivering apps that work seamlessly across both iOS and Android platforms, reducing development time and cost while maintaining high performance.`,
            `<span><h4 class="inline">Flutter App Development:</h4></span> Utilizing Google’s Flutter framework, we build natively compiled mobile, web, and desktop applications from a single codebase, ensuring fast development and consistent performance across platforms.`,
            `<span><h4 class="inline">Enterprise App Development:</h4></span> We develop secure and scalable enterprise applications tailored to streamline your business processes, enhance productivity, and facilitate effective organizational communication.`,
            `<span><h4 class="inline">IoT App Development:</h4></span> Our IoT app development services connect your devices and systems, creating intelligent applications that facilitate real-time data exchange and automation, driving innovation and efficiency.`,
            `<span><h4 class="inline">And More!</h4></span> We also offer additional services, including app maintenance and support, app migration, <a href="/ui-ux-designing/" target="_blank" title='UI/UX design'>UI/UX design</a>, and consulting to ensure your mobile app strategy is comprehensive and successful. <a href="/contact-us/" title="Contact us" target="_blank">Contact us</a> for any assistance. .`
        ]
    },
    {
      para: [
        `Octet is one of the best mobile app development company that ensures you receive unparalleled expertise and dedication.`,
        `With years of experience, our team of seasoned mobile app developers and designers delivers high-quality, user-friendly, and innovative mobile applications.`,
        `We offer flexible engagement models tailored to your needs and maintain a rigorous development process with stringent quality checks to ensure flawless performance.`,
        `Our client-centric approach, timely delivery, and comprehensive post-launch support make us a trusted partner for businesses across diverse industries.`
      ],
    },
    {
      para: [
        `The time required to develop a mobile application varies depending on project complexity, features, and platform requirements.`,
        `A typical mobile app project can take 3 to 6 months from the initial consultation to launch.`,
        `Our agile development methodology allows us to manage the project timeline efficiently, ensuring timely delivery without compromising quality.`,
        `For a more accurate estimate, we recommend you <a href="/contact-us/" title="contact us" target="_blank">contact us</a>.`
      ]
    },
    {
      para: [
        `The cost of developing a mobile app with Octet depends on various factors, including its complexity, features, design requirements, and the platforms it will be created for (iOS, Android, or both).`,
        `We offer competitive pricing and transparent billing practices to ensure you receive excellent value for your investment. `,
        `To provide a precise quote, we assess your project requirements and offer a detailed estimate tailored to your needs. `,
        `<a href="/contact-us/" title="contact us" target="_blank">Contact us</a> to get a personalized quote.`
      ],
    },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What mobile app development services do you offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "iOS App Development
          Our iOS app development services provide customized applications for Apple devices, ensuring seamless performance, superior user experience, and adherence to Apple’s strict guidelines.
          Android App Development
          We specialize in creating robust, user-friendly Android apps that run smoothly across various devices and Android versions, maximizing your reach in the largest mobile market.
          Hybrid App Development
          Our hybrid app development combines the best of both worlds, delivering apps that work seamlessly across both iOS and Android platforms, reducing development time and cost while maintaining high performance.
          Flutter App Development
          Utilizing Google’s Flutter framework, we build natively compiled mobile, web, and desktop applications from a single codebase, ensuring fast development and consistent performance across platforms.
          Enterprise App Development
          We develop secure and scalable enterprise applications tailored to streamline your business processes, enhance productivity, and facilitate effective organizational communication.
          IoT App Development
          Our IoT app development services connect your devices and systems, creating intelligent applications that facilitate real-time data exchange and automation, driving innovation and efficiency.
          And More!
          We also offer additional services, including app maintenance and support, app migration, UI/UX design, and consulting to ensure your mobile app strategy is comprehensive and successful. Contact us for any assistance."
              }
            },{
              "@type": "Question",
              "name": "Why should I choose Octet as a mobile app development company?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet is one of the best mobile app development company that ensures you receive unparalleled expertise and dedication. 
          
          With years of experience, our team of seasoned mobile app developers and designers delivers high-quality, user-friendly, and innovative mobile applications. 
          
          We offer flexible engagement models tailored to your needs and maintain a rigorous development process with stringent quality checks to ensure flawless performance. 
          
          Our client-centric approach, timely delivery, and comprehensive post-launch support make us a trusted partner for businesses across diverse industries."
              }
            },{
              "@type": "Question",
              "name": "How much time do you take for mobile application development?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The time required to develop a mobile application varies depending on project complexity, features, and platform requirements. 
          
          A typical mobile app project can take 3 to 6 months from the initial consultation to launch. 
          
          Our agile development methodology allows us to manage the project timeline efficiently, ensuring timely delivery without compromising quality. 
          
          For a more accurate estimate, we recommend you contact us."
              }
            },{
              "@type": "Question",
              "name": "How much does it cost to develop a mobile app with Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The cost of developing a mobile app with Octet depends on various factors, including its complexity, features, design requirements, and the platforms it will be created for (iOS, Android, or both). 
          
          We offer competitive pricing and transparent billing practices to ensure you receive excellent value for your investment. 
          
          To provide a precise quote, we assess your project requirements and offer a detailed estimate tailored to your needs. 
          
          Contact us to get a personalized quote."
              }
            }]
          }                                               
        `}
      </script>
    </Helmet>
    <Layout hire="Mobile App Development Company" hotjarHire={true} footerText="Mobile App Development Company">
      <div>
        <HireBanner data={banner} hire pageName="Mobile App Development Company" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={'Explore common questions about hiring CRO specialist to enhance your digital strategies and optimize your conversion rate effectively.'} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Mobile App Development Company | Mobile App Developers"
    description="The experienced developers at our mobile app development company offer custom mobile app development services for Android and iOS devices. Contact us now."
  />
);
